<template>
  <div>
    <content-not-view v-if="!subPermission.index" />
    <loading v-else-if="isLoading" />

    <b-card v-else>
      <div
        class="custom-search row d-md-flex justify-content-between mb-1 px-1"
      >
        <!-- search input -->
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('g.searchHere')"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
        <b-form-select
          no-caret
          class="col-lg-2 col-md-3 col-sm-11"
          v-model="filterValue"
          size="sm"
        >
          <option value="0" selected disabled>
            {{ $t("g.report/list/select/sort_by") }}
          </option>
          <option
            v-for="item in filteringValues"
            :key="item.text"
            :value="item.value"
          >
            {{ item.text }}
          </option>
        </b-form-select>
      </div>
      <vue-good-table
          class="my-2"
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table condensed"
          :sort-options="{
            enabled: false,
          }"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
        >
          <template slot="table-column" slot-scope="props">
            <!-- Column: Name -->

            <span
              v-if="props.column.label === 'user'"
              class="text-nowrap d-flex justify-content-center"
            >
              {{ $t("g.reports/listOfReports/table/header/user") }}
            </span>
            <span
              v-if="
                props.column.label === 'Type' &&
                (filterValue == 0 || filterValue == null)
              "
              class="text-nowrap d-flex justify-content-center"
            >
              {{ $t("g.reports/listOfReports/table/header/reportable_type") }}
            </span>
            <span
              v-if="props.column.label === 'Reoprted_reason'"
              class="text-nowrap d-flex justify-content-center"
            >
              {{ $t("g.reports/listOfReports/table/header/reoprted_reason") }}
            </span>
            <span
              v-if="props.column.label === 'Report_status'"
              class="text-nowrap d-flex justify-content-center"
            >
              {{ $t("g.reports/listOfReports/table/header/Report_status") }}
            </span>
            <span
              v-if="
                props.column.field === 'action' &&
                (subPermission.destroy || subPermission.show)
              "
              class="text-nowrap"
            >
              {{ $t("g.reports/listOfReports/table/header/actions") }}
            </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span
              v-if="props.column.field === 'reported_user'"
              class="text-nowrap pl-1 d-flex justify-content-center"
            >
              {{ props.row.user.first_name }} {{ props.row.user.middle_name }}
            </span>
            <span
              v-if="
                props.column.field === 'reportable_type' &&
                (filterValue == 0 || filterValue == null)
              "
              class="text-nowrap pl-1 d-flex justify-content-center"
            >
              {{ props.row.reportable_type.split("\\")[2] }}
            </span>

            <span
              v-if="props.column.field === 'Reoprted_reason'"
              class="text-nowrap pl-1 d-flex justify-content-center"
            >
              {{ props.row.reportReason.name }}
            </span>
            <span
              v-if="props.column.field === 'Report_status'"
              class="text-nowrap pl-1 d-flex justify-content-center"
            >
              {{
                props.row.closed_at
                  ? $t("g.report/table/label/action/close")
                  : $t("open")
              }}
            </span>
            <span
              v-if="
                props.column.field === 'action' &&
                (subPermission.destroy || subPermission.show)
              "
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="subPermission.show"
                    :to="{
                      name: 'viewReport',
                      params: { id: props.row.id },
                    }"
                  >
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span>{{ $t("g.view") }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="subPermission.destroy"
                    @click="deleteAlert(props.row.id)"
                  >
                    <feather-icon icon="Trash2Icon" class="mr-50" />
                    <span>{{ $t("g.delete") }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>
          <template slot="emptystate">
          <span class="text-center" style="width: 100%; display: inline-block;">
            {{ $t("g.no_data_for_table") }}
          </span>
        </template>
        </vue-good-table>
    </b-card>
  </div>
</template>
<script>
import {
  BAvatar,
  BRow,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BButton,
  BDropdownItem,
  BDropdown,
  VBTooltip,
  BLink,
  BFormSelectOption,
} from "bootstrap-vue";
import loading from "@/components/loading/loading.vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import "vue-good-table/dist/vue-good-table.css";
import contentNotView from "@/components/contentNotView/contentNotView.vue";

export default {
  name: "ListOfRepots",
  components: {
    contentNotView,
    BAvatar,
    BRow,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    BDropdownItem,
    BDropdown,
    VBTooltip,
    VueGoodTable,
    loading,
    BLink,
    BFormSelectOption,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      columns: [
        {
          label: "user",
          field: "reported_user",
          searchable: true,
        },
        {
          label: "Type",
          field: "reportable_type",
        },
        {
          label: "Reoprted_reason",
          field: "Reoprted_reason",
        },
        {
          label: "Report_status",
          field: "Report_status",
        },
        {
          label: "actions",
          field: "action",
        },
      ],
      rows: [],
      data: [],
      searchTerm: null,
      filterValue: 0,
      filteringValues: [
        {
          text: this.$t("g.report/list/select/Post"),
          value: "App\\Models\\Post",
        },
        {
          text: this.$t("g.report/list/select/comment"),
          value: "App\\Models\\Comment",
        },
        {
          text: this.$t("g.report/list/select/user"),
          value: "App\\Models\\User",
        },
        { text: this.$t("g.report/list/select/all"), value: null },
      ],
    };
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "reports");
      return this.$store.state.userData.sub_Permission;
    },
  },
  watch: {
    filterValue(newVal, oldVal) {
      const AllowedToSelectValues = [null, "0"];
      if (!AllowedToSelectValues.includes(newVal)) {
        this.rows = this.data.filter((e) => e.reportable_type == newVal);
      } else {
        this.rows = this.data;
      }
    },
  },

  mounted() {
    this.getLevelsList();
  },

  methods: {
    getLevelsList() {
      this.$http.get("admin/reports").then((res) => {
        this.data = res.data.data;
        const AllowedToSelectValues = [null, 0];
        if (!AllowedToSelectValues.includes(this.filterValue)) {
          this.rows = this.data.filter(
            (e) => e.reportable_type == this.filterValue
          );
        } else {
          this.rows = res.data.data;
        }
        this.filterValue = this.filterValue;
        this.isLoading = false;
      });
    },
    deleteAlert(id) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteEduLevel(id);
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    deleteEduLevel(id) {
      this.$http
        .delete(`admin/reports/${id}`)
        .then((res) => {
          if (res.status === 200) {
            this.getLevelsList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.subPermission.index)?_c('content-not-view'):(_vm.isLoading)?_c('loading'):_c('b-card',[_c('div',{staticClass:"custom-search row d-md-flex justify-content-between mb-1 px-1"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":_vm.$t('g.searchHere'),"type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)]),_c('b-form-select',{staticClass:"col-lg-2 col-md-3 col-sm-11",attrs:{"no-caret":"","size":"sm"},model:{value:(_vm.filterValue),callback:function ($$v) {_vm.filterValue=$$v},expression:"filterValue"}},[_c('option',{attrs:{"value":"0","selected":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t("g.report/list/select/sort_by"))+" ")]),_vm._l((_vm.filteringValues),function(item){return _c('option',{key:item.text,domProps:{"value":item.value}},[_vm._v(" "+_vm._s(item.text)+" ")])})],2)],1),_c('vue-good-table',{staticClass:"my-2",attrs:{"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table condensed","sort-options":{
          enabled: false,
        },"search-options":{
          enabled: false,
          externalQuery: _vm.searchTerm,
        }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label === 'user')?_c('span',{staticClass:"text-nowrap d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.$t("g.reports/listOfReports/table/header/user"))+" ")]):_vm._e(),(
              props.column.label === 'Type' &&
              (_vm.filterValue == 0 || _vm.filterValue == null)
            )?_c('span',{staticClass:"text-nowrap d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.$t("g.reports/listOfReports/table/header/reportable_type"))+" ")]):_vm._e(),(props.column.label === 'Reoprted_reason')?_c('span',{staticClass:"text-nowrap d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.$t("g.reports/listOfReports/table/header/reoprted_reason"))+" ")]):_vm._e(),(props.column.label === 'Report_status')?_c('span',{staticClass:"text-nowrap d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.$t("g.reports/listOfReports/table/header/Report_status"))+" ")]):_vm._e(),(
              props.column.field === 'action' &&
              (_vm.subPermission.destroy || _vm.subPermission.show)
            )?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.reports/listOfReports/table/header/actions"))+" ")]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'reported_user')?_c('span',{staticClass:"text-nowrap pl-1 d-flex justify-content-center"},[_vm._v(" "+_vm._s(props.row.user.first_name)+" "+_vm._s(props.row.user.middle_name)+" ")]):_vm._e(),(
              props.column.field === 'reportable_type' &&
              (_vm.filterValue == 0 || _vm.filterValue == null)
            )?_c('span',{staticClass:"text-nowrap pl-1 d-flex justify-content-center"},[_vm._v(" "+_vm._s(props.row.reportable_type.split("\\")[2])+" ")]):_vm._e(),(props.column.field === 'Reoprted_reason')?_c('span',{staticClass:"text-nowrap pl-1 d-flex justify-content-center"},[_vm._v(" "+_vm._s(props.row.reportReason.name)+" ")]):_vm._e(),(props.column.field === 'Report_status')?_c('span',{staticClass:"text-nowrap pl-1 d-flex justify-content-center"},[_vm._v(" "+_vm._s(props.row.closed_at ? _vm.$t("g.report/table/label/action/close") : _vm.$t("open"))+" ")]):_vm._e(),(
              props.column.field === 'action' &&
              (_vm.subPermission.destroy || _vm.subPermission.show)
            )?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(_vm.subPermission.show)?_c('b-dropdown-item',{attrs:{"to":{
                    name: 'viewReport',
                    params: { id: props.row.id },
                  }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EyeIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.view")))])],1):_vm._e(),(_vm.subPermission.destroy)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteAlert(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Trash2Icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.delete")))])],1):_vm._e()],1)],1)]):_vm._e()]}}])},[_c('template',{slot:"emptystate"},[_c('span',{staticClass:"text-center",staticStyle:{"width":"100%","display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.$t("g.no_data_for_table"))+" ")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }